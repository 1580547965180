<template>
    <v-container ref="pageCatalogs" fluid tag="section">

        <material-card icon="mdi-clipboard-text" icon-small title="Каталог товарів" color="accent" class="mb-16">
            <!--Фильтры-->
            <v-row class="mx-2 mb-2">
                <v-col cols="12" md="3">
                    <v-select return-object item-text="name" item-value="name" v-model="project" :items="projects"
                        label="Проект" clearable @change="getCatalog()"></v-select>
                </v-col>
                <!--Зберегти: "механіка" + "опис-->
                <v-col cols="12" md="9" class="text-right" v-if="category.id > 0">
                    <v-btn v-if="isManager || isAdmin" color="primary" min-width="150" @click="save()">
                        Зберегти: "опис" + "бренди"
                    </v-btn>
                </v-col>
                <v-col cols="12" md="9" class="text-right" v-if="category.id < 0">
                    <v-btn v-if="isManager || isAdmin" color="primary" min-width="150" @click="save()">
                        Додати нову категорію
                    </v-btn>
                </v-col>

            </v-row>
            <v-container fluid fill-height v-if="catalog.length">
                <v-row>
                    <v-col xl="3" lg="3" md="4">
                        <v-treeview :items="catalog" activatable>
                            <template v-slot:label="{ item }">
                                <span @click="getCategory(item.id)" style="cursor:pointer"
                                    :class="item.locked ? `grey--text` : ``">
                                    {{ item.name }}
                                </span>
                            </template>
                        </v-treeview>

                    </v-col>
                    <v-col xl="9" lg="9" md="8" v-if="category.id">
                        <v-tabs v-model="tabMainActive" :show-arrows="true" dark background-color="primary">
                            <v-tab v-for="tab in tabsMain" :key="tab.id" @click="changeRoute(tab.id)">
                                {{ tab.name }}
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tabMainActive" :touchless="true">
                            <!--Таб Опис-->
                            <v-tab-item :eager="true">
                                <v-col>
                                    <v-text-field label="Проєкт" v-model="project.name" readonly></v-text-field>
                                    <v-select item-text="name" item-value="id" v-model="category.parent_id"
                                        :items="firstLevelCats" label="Батьківська категорія" clearable
                                        required></v-select>
                                    <v-text-field label="Позиція" v-model="category.ord"></v-text-field>
                                    <v-text-field label="URL" v-model="category.url" required> </v-text-field>
                                    <v-checkbox v-model="category.is_enabled" :true-value="1" :false-value="0"
                                        label="Включено"></v-checkbox>

                                </v-col>
                                <v-tabs v-model="tab" show-arrows>
                                    <v-tab v-for="lang in localization" :key="lang.lang_key">
                                        {{ lang.name }}
                                    </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tab">
                                    <v-tab-item v-for="lang in localization" :key="lang.lang_key" :eager="true">
                                        <template v-if="categoryLang[lang.lang_id]">
                                            <v-col>
                                                <v-form 
                                                    lazy-validation v-model="isValidForm">
                                                    <v-text-field label="Назва"
                                                        v-model="categoryLang[lang.lang_id].name"
                                                        required></v-text-field>
                                                    <v-text-field label="Коротка назва"
                                                        v-model="categoryLang[lang.lang_id].name_short"
                                                        required></v-text-field>
                                                    <v-text-field label="Перший include"
                                                        v-model="categoryLang[lang.lang_id].text_include_first"
                                                        required></v-text-field>
                                                    <v-text-field label="Другий include"
                                                        v-model="categoryLang[lang.lang_id].text_include_second"
                                                        required></v-text-field>
                                                    <div class="text-h4  grey lighten-3 pa-1">Стартова сторінка</div>
                                                    <ImageUpload label="Фото сторінки" ref="imageUpload4"
                                                        v-model="categoryLang[lang.lang_id].image_index"
                                                        @image-upload:change="changeImage($event, {
                                                            lang_key: lang.lang_key,
                                                            lang_name: lang.lang_name,
                                                            object_image_id: categoryLang[lang.lang_id].category_id,
                                                            image_type: 'image_index',
                                                        })" :imageBaseUrl="imagePageBaseUrl + 'index/'" />
                                                    <ImageUpload label="Мобільне Фото сторінки" ref="imageUpload3"
                                                        v-model="categoryLang[lang.lang_id].mobile_image_index"
                                                        @image-upload:change="changeImage($event, {
                                                            lang_key: lang.lang_key,
                                                            lang_name: lang.lang_name,
                                                            object_image_id: categoryLang[lang.lang_id].category_id,
                                                            image_type: 'mobile_image_index',
                                                        })" :imageBaseUrl="imagePageBaseUrl + 'index/'" />
                                                    <!--Повний опис-->

                                                    <div>Текст:</div>
                                                    <v-text-field label="Заголовок"
                                                        v-model="categoryLang[lang.lang_id].header_index"></v-text-field>
                                                    <v-text-field label="Текст"
                                                        v-model="categoryLang[lang.lang_id].text_index"></v-text-field>
                                                    <br>
                                                    <div class="text-h4 grey lighten-3 pa-1">Портальна сторінка</div>
                                                    <ImageUpload label="Фото сторінки" ref="imageUpload2"
                                                        v-model="categoryLang[lang.lang_id].image_portal"
                                                        @image-upload:change="changeImage($event, {
                                                            lang_key: lang.lang_key,
                                                            lang_name: lang.lang_name,
                                                            object_image_id: categoryLang[lang.lang_id].category_id,
                                                            image_type: 'image_portal',
                                                        })" :imageBaseUrl="imagePageBaseUrl + 'portal/'" />
                                                    <div>Текст:</div>
                                                    <v-text-field label="Текст"
                                                        v-model="categoryLang[lang.lang_id].text_portal"></v-text-field>
                                                    <br>
                                                    <div class="text-h4 grey lighten-3 pa-1">Cторінка категорії</div>
                                                    <ImageUpload label="Зображення (баннер)" ref="imageUpload1"
                                                        v-model="categoryLang[lang.lang_id].image_category"
                                                        @image-upload:change="changeImage($event, {
                                                            lang_key: lang.lang_key,
                                                            lang_name: lang.lang_name,
                                                            object_image_id: categoryLang[lang.lang_id].category_id,
                                                            image_type: 'image_category',
                                                        })" :imageBaseUrl="imagePageBaseUrl + 'category/'" />
                                                    <ImageUpload label="Мобільне зображення (баннер)" ref="imageUpload"
                                                        v-model="categoryLang[lang.lang_id].mobile_image_category"
                                                        @image-upload:change="changeImage($event, {
                                                            lang_key: lang.lang_key,
                                                            lang_name: lang.lang_name,
                                                            object_image_id: categoryLang[lang.lang_id].category_id,
                                                            image_type: 'mobile_image_category',
                                                        })" :imageBaseUrl="imagePageBaseUrl + 'category/'" />

                                                </v-form>
                                            </v-col>
                                        </template>
                                    </v-tab-item>
                                </v-tabs-items>

                                <!-- <pre>{{ category }}</pre> -->
                            </v-tab-item>
                            <!--Таб Бренди-->
                            <v-tab-item :eager="true">
                                <v-data-table :headers="categoriesBrandHeaders" :items="categories_brand" sort-by="ord"
                                :footer-props="{itemsPerPageOptions: [20, 50, 100, -1]}" class="elevation-1">
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <v-toolbar-title>Бренди</v-toolbar-title>
                                            <v-divider class="mx-4" inset vertical></v-divider>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" @click="addBrand()">Додати бренд</v-btn>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:item.image="{ item }">
                                        <v-img :src="`${imageBrandsBaseUrl}${item.image}`" max-height="50"
                                            max-width="50"></v-img>
                                    </template>
                                    <template v-slot:item.ord="props">
                                        <v-edit-dialog :return-value.sync="props.item.ord" large persistent
                                            @save="saveBrandOrd(props.item)" save-text="Зберегти" @open="tempOrd = props.item.ord" 
                                            cancel-text="Повернутись">
                                            <div>{{ props.item.ord }}</div>
                                            <template v-slot:input>
                                                <div class="mt-4 text-h6">
                                                    Змінити сортування
                                                </div>
                                                <v-text-field v-model="tempOrd" label="Редагувати" single-line type="number"
                                                    @keydown="allowOnlyNumbers" counter autofocus></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <v-btn icon @click="deleteBrand(item)">
                                            <v-icon color="red">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                                <!-- <pre>{{ categories_brand }}</pre> -->
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>

                </v-row>


            </v-container>

            <!--Диалоговое окно для удаления-->
            <DialogConfitmDelete ref="dialogConfirmDelete" />
            <v-dialog v-model="dialogAddBrand" max-width="500">
                <v-card>
                    <v-card-title>Додати бренд</v-card-title>
                    <v-card-text>
                        <v-autocomplete item-text="name" item-value="id" v-model="brand.brand_id" :items="brands"
                            label="Бренд" required></v-autocomplete>
                        <v-text-field label="Позиція" v-model="brand.ord" required></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="saveBrand()">Зберегти</v-btn>
                        <v-btn color="error" @click="dialogAddBrand = false">Скасувати</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <Preloader v-if="isSending" />
        </material-card>

        <v-btn v-if="project.id && (isManager || isAdmin)" class="add-user__button" fab dark color="primary"
            @click="getCategory('add')">
            <v-icon dark>
                mdi-plus
            </v-icon>
        </v-btn>

    </v-container>
</template>



<script>
import {
    HTTP
} from '@/http-common';
import {
    mapGetters,
    mapMutations,
    mapActions
} from 'vuex'

import { IMAGE_BASE_URL } from '@/env.config';

export default {
    name: 'RegularTablesView',
    components: {
    },
    data() {
        return {
            tempOrd: null,
            isValidForm: true,
            catalog: [],
            category: {},
            brands: [],
            categories_brand: [],
            // Тецущий проект
            project: {},
            // Типы акций
            mis: null,
            // Выполняется отправка на сервер
            isSending: false,
            tabMainActive: 0,
            tabsMain: [
                { name: "Опис", id: 'description' },
                { name: "Бренди", id: 'brands' },
            ],
            tab: 0,
            customToolbar: [
                ["bold", "italic", "underline", "strike"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }],
                [{
                    color: ['#114A8C', '#000000',
                        '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
                        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5',
                        '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466']
                },
                {
                    background: ['#114A8C', '#000000',
                        '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
                        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5',
                        '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466']
                }],
                ["link"],
                ["clean"]
            ],
            errorRequired: `Це поле обов'язкове`,
            imagePageBaseUrl: `${IMAGE_BASE_URL}categories/`,
            imageBrandsBaseUrl: `${IMAGE_BASE_URL}brands/`,
            imagesToSend: {},
            categoriesBrandHeaders: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: 'Бренд', value: 'name' },
                { text: 'Зображення', value: 'image' },
                { text: 'Позиція', value: 'ord' },
                { text: 'Управління', value: 'action' },
            ],
            dialogAddBrand: false,
            brand: {
                brand_id: 0,
                ord: 0,
                category_id: 0,
            },
        }
    },
    computed: {
        ...mapGetters(['isManager', 'isAdmin', 'projects', 'localization']),
        firstLevelCats() {
            return this.catalog.filter(cat => cat.level == 1)
        },
        categoryLang() {
            return this.category.langs.reduce((acc, lang) => {
                lang.image_index = lang.image_index ? (this.imagePageBaseUrl + 'index/' + lang.image_index) : null;
                lang.mobile_image_index = lang.mobile_image_index ? (this.imagePageBaseUrl + 'index/' + lang.mobile_image_index) : null;
                lang.image_portal = lang.image_portal ? (this.imagePageBaseUrl + 'portal/' + lang.image_portal) : null;
                lang.image_category = lang.image_category ? (this.imagePageBaseUrl + 'category/' + lang.image_category) : null;
                lang.mobile_image_category = lang.mobile_image_category ? (this.imagePageBaseUrl + 'category/' + lang.mobile_image_category) : null;

                acc[lang.lang_id] = lang;
                return acc;
            }, {})
        },
    },

    mounted() {
        this.init();
    },
    watch: {
        '$route': {
            handler() {
            },
            deep: true,
        }
    },

    methods: {
        ...mapMutations(['setSnackbar']),
        ...mapActions(['getProjects', 'getLocalization']),

        async init() {
            await this.getProjects();
            await this.getLocalization();
            await this.getBrands();
        },
        allowOnlyNumbers(event) {
            // Дозволяємо лише цифри та клавіші управління
            if (
                !(
                event.key >= '0' && event.key <= '9' || // Цифри
                ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(event.key) || // Клавіші управління
                (event.ctrlKey && ['a', 'c', 'v', 'x'].includes(event.key.toLowerCase())) // Комбінації Ctrl+A, Ctrl+C тощо
                )
            ) {
                event.preventDefault()
            }
        },
        // Получить каталог
        async getCatalog() {
            try {
                const {
                    data
                } = await HTTP.post('/get-catalog', {
                    project: this.project.id
                });
                this.catalog = data?.content?.catalog || [];
            } catch (error) {
                console.warn(error);
            }
        },
        async getCategory(id, skipSetTab) {
            try {
                if (id == 'add') {
                    this.setSnackbar({
                        text: 'Додавання нової категорії',
                        type: 'warning',
                        show: true
                    });

                    this.category = {
                        id: -1,
                        project_id: this.project.id,
                        parent_id: 0,
                        ord: 0,
                        url: '',
                        is_enabled: 1,
                        level: null,
                        langs: this.localization.map(lang => ({
                            lang_id: lang.lang_id,
                            name: '',
                            name_short: '',
                            text_include_first: '',
                            text_include_second: '',
                            header_index: '',
                            text_index: '',
                            text_portal: '',
                            image_index: '',
                            mobile_image_index: '',
                            image_portal: '',
                            image_category: '',
                            mobile_image_category: '',
                        }))
                    }
                    this.tabMainActive = 0;
                    return;
                } else {
                    const {
                        data
                    } = await HTTP.post('/get-category', { id });
                    this.category = data.content || {};
                    if (!skipSetTab) this.tabMainActive = 0;
                    const res = await HTTP.post('/get-categories-brand', { id });
                    this.categories_brand = res.data.content || [];
                    //перебрати усі локалізації this.localization встановити індекс idx кожної локалізації в this.$refs.imageUpload[idx].restoreImage();
                    for (let idx = 0; idx < this.localization.length; idx++) {
                        this.$refs.imageUpload[idx].restoreImage();
                        this.$refs.imageUpload1[idx].restoreImage();
                        this.$refs.imageUpload2[idx].restoreImage();
                        this.$refs.imageUpload3[idx].restoreImage();
                        this.$refs.imageUpload4[idx].restoreImage();                    
                    }
                }
            } catch (error) {
                console.warn(error);
            }
        },
        async save() {
            await this.updateCategory();
            let isImages = Object.keys(this.imagesToSend).length;

            this.setSnackbar({
                type: 'success',
                text: `Категорію оновлено. ${isImages ? 'Виконується завантаження зображень.' : ''}`
            });

            if (!isImages) {
                this.isSending = false;
            } else {
                this.runProcessImagesSend(this.category.id);
            }

            await this.getCatalog();
            await this.getCategory(this.category.id);
        },
        // Запускает процесс отравки изображений, отправкой занимается "ImageUpload"
        async runProcessImagesSend(actionTypeId) {
            let errors = '';
            for (let key in this.imagesToSend) {
                const [lang_key, image_type] = key.split(' ');
                let item = this.imagesToSend[key];
                let queryParams = {
                    lang_key,
                    object_image_id: actionTypeId,
                    image_type
                };
                let result = await item.sendImage(queryParams);
                // Если при загрузке изображений были - показать попап
                if (!result.error) {
                    delete this.imagesToSend[key];
                } else {
                    errors += `${result.error}</br>`;
                    this.setSnackbar({ type: 'error', text: errors });
                }
            }
            this.isSending = false;
        },
        // Изменить список картинок 
        changeImageList(data, langKey) {
            if (data.action) {
                this.imagesToSend[langKey] = {
                    sendImage: data.sendImage
                };
            } else {
                delete this.imagesToSend[langKey];
            }
        },

        async updateCategory() {
            try {
                this.isSending = true;
                this.category.level = this.category.parent_id ? 2 : 1;
                const {
                    data
                } = await HTTP.post('/update-category', this.category);
                this.isSending = false;
                this.setSnackbar({
                    text: 'Дані збережено',
                    type: 'success',
                    show: true
                });
            } catch (error) {
                this.isSending = false;
                this.setSnackbar({
                    text: 'Помилка збереження',
                    type: 'error',
                    show: true
                });
                console.warn(error);
            }

        },
        changeImage(event, params) {
            let key = `${params.lang_key} ${params.image_type}`
            if (event.action) {
                event.params = params;
                this.$set(this.imagesToSend, key, event);
            } else {
                delete this.imagesToSend[key];
            }
            this.$emit('form:image-change', this.imagesToSend);
        },
        changeRoute(tabId) {
            if (this.$route.query.tab != tabId) {
                this.$router.push({ query: { tab: tabId } });
            }
        },
        async saveBrandOrd(item) {
            item.ord = this.tempOrd 
            const {
                data
            } = await HTTP.post('/update-categories-brand', item);
            await this.getCategory(this.category.id, true);
        },
        async deleteBrand(item) {
            const {
                data
            } = await HTTP.post('/delete-categories-brand', item);
            this.categories_brand = this.categories_brand.filter(brand => brand.id != item.id);
        },
        addBrand() {
            this.dialogAddBrand = true;
        },
        async saveBrand() {
            this.brand.category_id = this.category.id;
            await HTTP.post('/add-categories-brand', this.brand)
            this.dialogAddBrand = false;
            await this.getCategory(this.category.id);
        },
        async getBrands() {
            try {
                let { data } = await HTTP.post('/get-all-brands');
                this.brands = data?.content || [];
            } catch (error) {
                console.warn(error);
            }
        },


    },
}
</script>


<style>
.add-user__button {
    position: fixed;
    bottom: 20px;
    right: 20px;
}
</style>
